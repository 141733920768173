
import {
  Component,
  Prop,
  Watch,
  Vue,
} from 'vue-property-decorator';
import { Image, VueImagePickerPlugin } from './types';

@Component
export default class PickerUrl extends Vue implements VueImagePickerPlugin {
  public name = 'byurl';

  public showed = false;

  @Prop({
    default: () => {
      /* empty */
    },
  })
  private strings: { [key: string]: string };

  private t: { [key: string]: string };

  @Prop()
  private onEnter: (url: string) => Promise<void>;

  @Prop({ required: false })
  private buttonCustomClass?: string;

  @Prop({ required: false })
  private inputCustomClass?: string;

  private url = '';

  public setUrl(val: string) {
    this.url = val;
  }

  public enter() {
    if (!this.onEnter) {
      return;
    }

    this.onEnter(this.url);
  }

  public getValues(): Image[] {
    if (this.url) {
      return [
        {
          id: undefined,
          name: undefined,
          downloadUrl: this.url,
        },
      ];
    }

    return [];
  }

  @Watch('showed')
  public onShowed(val: boolean) {
    if (val) {
      this.$nextTick(() => {
        (this.$refs.input as HTMLInputElement).focus();
      });
    }
  }

  created() {
    this.t = {
      placeholder: 'Enter image URL',
    };

    this.t = Object.assign(this.t, this.strings);
  }

  @Watch('url')
  private onUrlChanged() {
    this.$emit('input', this.getValues()[0]);
  }
}
